import React, { useEffect, useLayoutEffect, useRef, useContext } from 'react';

import LogoNost from '../images/svg/logo-nost.svg';
import LogoRbs from '../images/svg/logo-rbs.svg';

import { PageWrapperContext } from './page-wrapper';

import '../styles/components/footer.scss';

const Footer = () => {

    const pageWrapperContext = useContext( PageWrapperContext );
    const footerRef = useRef();

    useEffect( () => {

        const handleResize = () => {
            pageWrapperContext.setFooterHeight( footerRef.current.clientHeight );
        };

        window.addEventListener( 'resize', handleResize );
        return () => window.removeEventListener( 'resize', handleResize );

    }, [] );

    useLayoutEffect( () => {
        pageWrapperContext.setFooterHeight( footerRef.current.clientHeight );
    } );

    return <footer className="footer container-fluid" ref={footerRef}>
        <div className="row bottom">
            <div className="col-2 col-sm-5">
                <p className="small">
                    <span className="inverted">Get in touch</span><br />
                    <a href="mailto:mail@n-ost.org">mail@n-ost.org</a><br />
                    <a href="mailto:playbook@n-ost.org">playbook@n-ost.org</a>
                </p>
            </div>
            <div className="col-1 col-sm-3 col-xs-4">
                <p className="small">
                    <span className="inverted">Connect</span><br />
                    <a href="https://twitter.com/n_ost" target="_blank" rel="noopener noreferrer nofollow">Twitter</a><br />
                    <a href="https://www.facebook.com/nost.online/" target="_blank" rel="noopener noreferrer nofollow">Facebook</a><br />
                </p>
            </div>
            <div className="col-2 col-sm-4 col-xs-8">
                <p className="small">
                    <span className="inverted">Address</span><br />
                    Alexandrinenstrasse 2-3<br />
                    d-10969 berlin<br />
                </p>
            </div>
            <div className="col-3 col-sm-5 col-xs-12">
                <p className="small">
                    <span className="inverted">Design: </span><a href="https://loonatiks.gr/" rel="noopener noreferrer nofollow">Loonatiks Design Crew</a><br />
                    <span className="inverted">Development: </span><a href="https://yannickgregoire.nl/" rel="noopener noreferrer nofollow">Yannick Gregoire</a>
                </p>
            </div>
            <div className="col-1 col-sm-3 col-xs-4 end-xs start-md last-xs">
                <p className="small">
                    <a href="https://n-ost.org/67-impressum" rel="noopener noreferrer nofollow">Disclaimer</a><br />
                </p>
            </div>
            <div className="col-2 col-sm-2 col-xs-4 end start-sm">
                <div className="logo-block">
                    <p className="small">
                        <span className="inverted">Supported by</span><br />
                        <a href="https://www.bosch-stiftung.de/" rel="noopener noreferrer nofollow"><LogoRbs className="logo-rbs" /></a>
                    </p>
                </div>
            </div>
            <div className="col-1 col-sm-2 col-xs-4 end start-sm">
                <div className="logo-block">
                    <p className="small">
                        <span className="inverted">Project of</span><br />
                        <a href="https://n-ost.org/" rel="noopener noreferrer nofollow"><LogoNost className="logo-nost" /></a>
                    </p>
                </div>
            </div>

        </div>
    </footer>;
};

export default Footer;
