
import React, { useState } from 'react';

import Button from '../button';
import Pill from '../pill';

import '../../styles/components/article/todo-list.scss';

const TodoList = () => {

    // TODO: Move data to props

    const [ selectedTags, setSelectedTags ] = useState( { preparation: [], working: [], publication: [] } );
    const [ hasSelectedTags, setHasSelectedTags ] = useState( false );
    const [ currentTopic, setCurrentTopic ] = useState( null );

    const topics = {
        preparation: {
            title: 'Starting out your cross-border-research',
            description: 'What are some things that your team should discuss during the first call?',
            tags: [
                { id: 1, name: 'big team' },
                { id: 2, name: 'small team' },
                { id: 3, name: 'investigation' },
                { id: 4, name: 'multimedia' },
                { id: 5, name: 'want to apply for a grant with this story' },
                { id: 6, name: 'applied for a grant with this story' },
                { id: 7, name: 'got a grant for this story' },
                { id: 8, name: 'sensitive topic' },
                { id: 9, name: 'conflict and risk zone reporting' },
                { id: 10, name: 'reporting from the location' },
                { id: 11, name: 'FOI heavy story' },
                { id: 12, name: 'document heavy story' },
                { id: 13, name: 'freelance' },
                { id: 14, name: 'travel' }
            ],
            todos: [
                { tags: [ 1, 3, 4, 5, 7, 6, 8, 11, 13 ], title: 'Choose and define responsibilities. Depending on the group, one person could be in charge of coordinating communication, one could set the agenda in meetings, another could take care of the budget and the fourth could prepare a timeline. What will the division of roles within the team be?' },
                { tags: [ 2, 1, 3, 4, 8, 9, 10, 13 ], title: 'Define the means of everyday communication, where your research material will be stored' },
                { tags: [ 2, 1, 3, 4, 8, 9, 10, 13 ], title: 'Define what security issues you might run into and how you could solve them' },
                { tags: [ 2, 1, 3, 4, 8, 13 ], title: 'Define the frequency of communication between team members (how many team calls, who prepares them, who’s in charge of the agenda, who takes minutes, what software you’ll use for the video calls, whether and when you are going to have real-time meetings)' },
                { tags: [ 2, 1, 3, 4, 8, 9, 10, 5, 7, 6, 13 ], title: 'Determine the timeline for your report/investigation (research, reporting, writing and publication phase; possible grants you’d want to apply to)' },
                { tags: [ 2, 1, 3, 4, 8, 9, 10, 5, 7, 6, 11, 13 ], title: 'Publication and distribution—decide if you’ll publish all articles at the same time; if you’ll have follow-up stories; if you’ll come out with a common story or publish individual national stories' },
                { tags: [ 2, 1, 3, 8, 9, 10, 11, 12, 13 ], title: 'Legal concerns—does your story need legal screening?; How can you get a lawyer? Is it not safe to publish your story in certain countries?; Will you need a lawyer to get access to certain documents?' },
                { tags: [ 2, 1, 3, 8, 9, 10, 11, 12, 5, 7, 6, 11, 13 ], title: 'Talk about money! Plan the exact salary distribution and costs you’re sure of encountering' },
                { tags: [ 2, 1, 3, 8, 9, 10, 13 ], title: 'Ethical considerations—discuss how you’ll approach sources and ask for documents in different countries; discuss naming sources and protecting them; discuss any potential cultural differences related to approaching sources and quoting them' },
                { tags: [ 2, 1, 3, 8, 9, 10, 13, 14 ], title: 'Are you insured? Consider if it makes sense to insure yourself and your equipment while reporting a story' }
            ]
        },
        working: {
            title: 'Already working on a cross-border research',
            description: 'What should be shared within the team while working on a project?',
            tags: [
                { id: 1, name: 'big team' },
                { id: 2, name: 'small team' },
                { id: 3, name: 'investigation' },
                // { id: 4, name: 'multimedia' },
                { id: 5, name: 'want to apply for a grant with this story' },
                { id: 6, name: 'applied for a grant with this story' },
                { id: 7, name: 'got a grant for this story' },
                { id: 8, name: 'sensitive topic' },
                { id: 9, name: 'conflict and risk zone reporting' },
                { id: 10, name: 'reporting from the location' },
                { id: 11, name: 'FOI heavy story' },
                { id: 12, name: 'document heavy story' },
                { id: 13, name: 'freelance' },
                { id: 14, name: 'travel' }
            ],
            todos: [
                { tags: [ 1, 2, 3, 8, 9, 10, 11, 12, 13 ], title: 'List of sources (who interviewed them, when, length, link to the transcript, remarks)' },
                { tags: [ 1, 2, 3, 8, 9, 10, 11, 12, 13 ], title: 'Data about people featured in an investigation and relevant institutions in each country (or transnational institutions, such as EU ones); links between people investigated (mapping)' },
                { tags: [ 1, 2, 3, 8, 9, 10, 11, 12, 5, 6, 7, 13 ], title: 'Updated timeline for the story' },
                { tags: [ 1, 2, 3, 8, 9, 10, 13 ], title: 'Interview template (it can be useful to come up with a template of questions one always asks the interviewees to be sure that the interviews are as harmonized as possible)' },
                { tags: [ 1, 3, 8, 9, 10, 12, 13 ], title: 'Working guidelines: Internal guidelines for the group—how much does one want to reveal to different sources, what to pay special intention to while asking for documents, etc.' },
                { tags: [ 1, 2, 3, 8, 9, 10, 12, 5, 6, 7, 13 ], title: 'Keeping track of money—have a shared folder to keep track of expenses and save all receipts.' },
                { tags: [ 1, 2, 3, 8, 12, 11, 9, 10, 14, 13 ], title: 'Uploading and sharing documents—keep track of all FOI requests and list updates as necessary; sources/documents/data chases should be allocated' },
                { tags: [ 1, 2, 3, 8 ], title: 'Master document for press passes, letters from relevant media proving that the reporters are working on a story for them; location releases; appearance releases' },
            ]
        },
        publication: {
            title: 'Planning to publish research findings soon',
            description: 'What should be on your check-up list before the publication of a cross-border story? ',
            tags: [
                { id: 1, name: 'big team' },
                { id: 2, name: 'small team' },
                { id: 3, name: 'investigation' },
                { id: 4, name: 'multimedia' },
                // { id: 5, name: 'want to apply for a grant with this story' },
                // { id: 6, name: 'applied for a grant with this story' },
                { id: 7, name: 'got a grant for this story' },
                { id: 8, name: 'sensitive topic' },
                { id: 9, name: 'conflict and risk zone reporting' },
                // { id: 10, name: 'reporting from the location' },
                { id: 11, name: 'FOI heavy story' },
                { id: 12, name: 'document heavy story' },
                { id: 13, name: 'freelance' },
                { id: 14, name: 'visual' },
                { id: 15, name: 'open data' }
            ],
            todos: [
                { tags: [ 1, 2, 3, 8, 12, 11, 9, 13 ], title: 'Fact check of the pieces' },
                { tags: [ 1, 2, 3, 4, 7, 8, 9, 11, 12, 13, 14, 15 ], title: 'Visuals—do you need photos, illustrations, data visualisation, animation?' },
                { tags: [ 1, 2, 3, 4, 7, 8, 13 ], title: 'Publication dates—are you going to publish simultaneously or not?' },
                { tags: [ 1, 2, 3, 4, 7, 8, 13, 14 ], title: 'Publication outlets—Decide what publications you’re going to pitch to, set up a list of priority publications and “Plan B” publications' },
                { tags: [ 1, 2, 3, 8, 12, 11, 9, 13 ], title: 'Ethical and legal concerns at this point' },
                { tags: [ 1, 2, 3, 4, 8, 9, 13, 14, 15 ], title: 'Promotion and publication strategy—are you going to use social media advertisement? What’s your strategy for social media promotion? Are you going to engage the community? ' },
                { tags: [ 1, 2, 3, 4, 8, 9, 11, 12, 13, 14, 15 ], title: 'Follow-ups on the story after the first publication' },
            ]
        }
    };

    const renderTopics = () => {
        if ( !currentTopic ) {
            return <>
                <h2 className="title">Are you:</h2>
                <div>
                    <Button expanded small onClick={() => { setCurrentTopic( 'preparation' ); }}>{topics.preparation.title}</Button>
                </div>
                <div>
                    <Button expanded small onClick={() => { setCurrentTopic( 'working' ); }}>{topics.working.title}</Button>
                </div>
                <div>
                    <Button expanded small onClick={() => { setCurrentTopic( 'publication' ); }}>{topics.publication.title}</Button>
                </div>
            </>;
        }
    };

    const renderTags = () => {
        if ( currentTopic && !hasSelectedTags ) {
            return <>
                <Button reversed expanded small onClick={() => { setCurrentTopic( null ); }}>Previous step</Button>
                <h2 className="title">{topics[currentTopic].description}</h2>
                <p>Please choose all the relevant hashtags that apply to your research and/or team:</p>
                <div className="tags">
                    {renderTagsForCurrentTopic()}
                </div>
                <Button expanded onClick={() => { setHasSelectedTags( true ); }}>Show todo's</Button>
            </>;
        }
    };

    const renderTagsForCurrentTopic = () => {
        return topics[currentTopic].tags.map( ( tag, index ) => {
            const selected = selectedTags[currentTopic].includes( tag.id );
            return <div className="tag" key={index} onClick={() => { clickTag( tag ); }}><Pill label={`#${tag.name}`} selected={selected} /></div>;
        } );
    };

    const clickTag = ( tag ) => {
        setSelectedTags( ( prevState ) => {
            const newState = prevState;
            const selected = prevState[currentTopic].includes( tag.id );

            if ( !selected ) {
                newState[currentTopic].push( tag.id );
            } else {
                newState[currentTopic] = newState[currentTopic].filter( id => id !== tag.id );
            }

            return { ...prevState, ...newState };
        } );
    };

    const renderTodos = () => {
        if ( hasSelectedTags ) {
            return <>
                <Button reversed expanded small onClick={() => { setHasSelectedTags( false ); }}>Previous step</Button>
                <h2 className="title">{topics[currentTopic].description}</h2>
                <ul className="todos">{renderTodosForTags()}</ul>
                <Button reversed expanded small onClick={() => { setHasSelectedTags( false ); }}>Previous step</Button>
            </>;
        }
    };

    const renderTodosForTags = () => {
        return topics[currentTopic].todos.map( ( todo, index ) => {
            let selected = false;

            todo.tags.map( ( id ) => {
                if ( selectedTags[currentTopic].includes( id ) ) {
                    selected = true;
                }
            } );

            if ( selected ) {
                return <li key={index}>{todo.title}</li>;
            }
        } );
    };

    return <section className="todo-list">
        {renderTopics()}
        {renderTags()}
        {renderTodos()}
    </section>;

};

export default TodoList;
