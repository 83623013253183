import React from 'react';

import TransitionLink from 'gatsby-plugin-transition-link';

import { transition } from '../components/config';

const Link = ( { to, children } ) => {
    return <TransitionLink to={to} {...transition}>
        {children}
    </TransitionLink>;
};

export default Link;
