import React, { useEffect } from 'react';

import IconArrow from '../../images/svg/icon-arrow.svg';
import TouchSwiper from 'swiper/bundle';

import '../../styles/components/article/swiper.scss';
import 'swiper/swiper-bundle.css';

const Swiper = ( { slides } ) => {

    useEffect( () => {

        new TouchSwiper( '.swiper-container', {
            loop: true,
            navigation: {
                nextEl: '.swiper-next',
            },
        } );

    }, [] );

    const renderSlides = () => {
        return slides.map( ( slide, index ) => {
            return <div className="swiper-slide" key={index}>
                <div className="image">
                    <img src={slide.src} />
                </div>
                <div className="meta">
                    <h4>{index + 1}. {slide.title}</h4>
                    <p>{slide.description}</p>
                </div>
            </div>;
        } );
    };

    return <section className="swiper">
        <div className="swiper-container">
            <div className="swiper-wrapper">
                {renderSlides()}
            </div>
        </div>
        <div className="swiper-nav">
            <div className="swiper-button swiper-next"><IconArrow /></div>
        </div>
    </section>;
};

export default Swiper;
