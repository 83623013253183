import React from 'react';

import '../../styles/components/article/iframe.scss';

const Iframe = ( { src } ) => {

    return <div className="iframe"><iframe src={src} frameBorder="0" allowFullScreen></iframe></div>;

};

export default Iframe;
