import React, { useEffect } from 'react';

const Flourish = ( { src, url } ) => {

    useEffect( () => {

        const script = document.createElement( 'script' );
        script.src = 'https://public.flourish.studio/resources/embed.js';
        script.async = true;
        document.body.appendChild( script );

        return () => {
            document.body.removeChild( script );
        };

    }, [] );

    return <div className="flourish-embed flourish-quiz" data-src={src} data-url={url} aria-label=""></div>;

};

export default Flourish;
