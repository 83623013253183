import React, { useState, useContext, useEffect } from 'react';

import IconArrow from '../../images/svg/icon-arrow.svg';

import { PageWrapperContext } from '../../components/page-wrapper';

import '../../styles/components/article/details.scss';

const Details = ( { label, children } ) => {

    const pageWrapperContext = useContext( PageWrapperContext );
    const [ expanded, setExpanded ] = useState( false );

    useEffect( () => {
        pageWrapperContext.setPageHeight( document.body.clientHeight );
    }, [ expanded ] );

    return <div className={`details ${expanded ? 'expanded' : ''}`}>
        <button className="details-button" onClick={ () => { setExpanded( !expanded );}}>
            <div className="label">{label}</div> 
            <div className="icon"><IconArrow /></div>
        </button>
        <section className="details-body">{children}</section>
    </div>;

};

export default Details;
