import React, { useEffect, useLayoutEffect, useState } from 'react';

import ScrollDown from './scroll-down';
import Navigation from './navigation';
import Footer from './footer';

import '../styles/components/page.scss';

const PageWrapper = ( { children } ) => {

    const [ pageClass, setPageClass ] = useState( '' );
    const [ pageHeight, setPageHeight ] = useState( 0 );
    const [ footerHeight, setFooterHeight ] = useState( 0 );

    useEffect( () => {

        const handleResize = () => {
            setPageHeight( document.body.clientHeight );
        };

        window.addEventListener( 'resize', handleResize );
        return () => window.removeEventListener( 'resize', handleResize );

    }, [] );

    useLayoutEffect( () => {
        setPageHeight( document.body.clientHeight );
    } );

    return (
        <PageWrapperContext.Provider value={{ pageHeight, setPageHeight, setPageClass, footerHeight, setFooterHeight }}>
            <main className={`page ${pageClass}`}>
                <main className="content-container">
                    {children}
                </main>
                <section className="content-margin" style={{ height: `${footerHeight}px` }}></section>
                <Footer />
            </main>
            <ScrollDown />
            <Navigation />
        </PageWrapperContext.Provider>
    );
};

export const PageWrapperContext = React.createContext();
export default PageWrapper;
