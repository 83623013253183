
import React from 'react';

import '../../styles/components/article/quiz.scss';

const Quiz = ( ) => {

    return <section className="quiz">
        <h2>Quiz component</h2>
    </section>;
    
};

export default Quiz;
