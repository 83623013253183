import ResourcesList from './article/resources-list';
import FloatingImage from './article/floating-image';
import TodoList from './article/todo-list';
import Flourish from './article/flourish';
import Details from './article/details';
import Podcast from './article/podcast';
import Swiper from './article/swiper';
import Iframe from './article/iframe';
import Column from './article/column';
import Image from './article/image';
import Quiz from './article/quiz';
import Row from './article/row';
import Box from './article/box';
import Button from './button';
import Quote from './quote';
import Link from './link';

export const transitionEntry = {
    length: 0,
    delay: .5
};

export const transitionExit = {
    length: .5,
    delay: 0
};

export const transition = { entry: transitionEntry, exit: transitionExit };

export const mdxComponents = { Link, Quiz, TodoList, ResourcesList, Details, Button, Quote, Flourish, Iframe, FloatingImage, Box, Column, Row, Image, Podcast, Swiper };

export const siteOrigin = ( process.env.NODE_ENV === 'production' ) ? 'https://playbook.n-ost.org' : '';

export const googleSiteVerification = 'BBCu7qEHoT-dCiiZxNlq6EylIvCA9Y9P14kaIPih51o';
