export const slides = [
    {
        src: '/images/articles/19-slide-01.png',
        title: 'Set clear deadlines',
        description: 'When speaking of deadlines, most people think of external deadlines with publishers. However, deadlines within the team are equally important. '
    },
    {
        src: '/images/articles/19-slide-02.png',
        title: 'Involve publishers early on',
        description: 'It is crucial to get in touch early with potential publishers. A reliably contact is more important in getting your story out than the perfect pitch that comes too late.  '
    },
    {
        src: '/images/articles/19-slide-03.png',
        title: 'Agree on minimum goals',
        description: 'Maximum goals are easy: exposing criminal networks, changing the world. But what is the absolute minimum goal you need to achieve to be able to publish? This discussion will reveal whether team members have the same priorities.'
    },
    {
        src: '/images/articles/19-slide-04.png',
        title: 'Share your calendars',
        description: 'Honesty about how much time you can give to the project is key. Your media outlet might still expect stories from you while you are working on the cross-border project. An ongoing lockdown, a national election, or even summer holidays might get in the way as well. It is better to let each other know about time availability than to cause frustration about unanswered emails and messages further down the line.'
    },
    {
        src: '/images/articles/19-slide-05.png',
        title: 'Speak to each other regularly',
        description: 'Agree on a fixed recurring time for video calls. You might think there isn’t much to talk about, but there always will be.'
    },
    {
        src: '/images/articles/19-slide-06.png',
        title: 'Take minutes during meetings',
        description: 'Take minutes and share them with the group. What is said and acknowledged in the meeting is what your team has collectively agreed upon. It’s surprising how much gets lost if it’s not written down.'
    },
    {
        src: '/images/articles/19-slide-07.png',
        title: 'Check in on your team',
        description: 'Don’t just speak about content. How are you doing as a team? How is everyone coping with the workload? Is one team member experiencing doubts or problems that another can help with? Are the deadlines and tasks still realistic?'
    },
    {
        src: '/images/articles/19-slide-08.png',
        title: 'Share your work early on',
        description: 'Share your work early on with your team, even if you don’t know how useful the material might be. Share excerpts or transcripts from interviews, background research, translations…if you wait too long, you’ll drown in the shared content when you actually have to write the story.'
    },
    {
        src: '/images/articles/19-slide-09.png',
        title: 'Summarize what you share',
        description: 'Don’t share a 400-page study, even if it’s super interesting. Share a summary and add a link to the original source. Don’t always share full interview transcripts, but only the most relevant and interesting quotes. Collect your main findings and present them as a backbone to the full investigation.'
    },
    {
        src: '/images/articles/19-slide-10.png',
        title: 'Have fun!',
        description: 'It’s a huge privilege to work in cross-border projects, making new connections and sharing insights. It’s worth the extra effort.'
    }
];