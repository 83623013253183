import React, { useEffect, useState, useContext } from 'react';

import IconArrow from '../images/svg/icon-arrow.svg';

import { useScrollPosition } from '../components/scroll-position';
import { useSpring, animated, config } from 'react-spring';
import { PageWrapperContext } from './page-wrapper';
import { useWindowSize } from './window-size';

import '../styles/components/scroll-down.scss';

const ScrollDown = () => {

    const pageWrapperContext = useContext( PageWrapperContext );
    const windowSize = useWindowSize();
    const scrollPosition = useScrollPosition();

    const [ scrollOffset, setScrollOffset ] = useState( 0 );

    const [ isScrolled, setIsScrolled ] = useState( false );
    const [ isBottom, setIsBottom ] = useState( false );

    const { transform } = useSpring( {
        transform: `translate3d(0, ${scrollOffset}px, 0)`,
        config: config.fast
    } );

    useEffect( () => {

        const pageHeight = pageWrapperContext.pageHeight - windowSize.height;

        setIsBottom( scrollPosition.y === pageWrapperContext.pageHeight - windowSize.height );
        setIsScrolled( scrollPosition.y > windowSize.height );
        setScrollOffset( ( scrollPosition.y > pageHeight - pageWrapperContext.footerHeight ) ? pageHeight - scrollPosition.y - pageWrapperContext.footerHeight : 0 );
        
    }, [ scrollPosition.y, pageWrapperContext.pageHeight ] );

    const scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    return <animated.div className={'scroll-down hide-xs'} style={{ transform }}>
        <div className={`scroll-down-button ${( isScrolled ) ? 'scroll-down-button--scrolled' : ''} ${( isBottom ) ? 'scroll-down-button--bottom' : ''}`}>
            <div className="icon" onClick={() => { scrollToTop(); }}><IconArrow /></div>
            <div className="label" onClick={() => { scrollToTop(); }}>{( isBottom ) ? 'Back to top' : 'Scroll down'}</div>
        </div>
    </animated.div>;
};

export default ScrollDown;
