import React from 'react';

import '../styles/components/pill.scss';

const Pill = ( { label, children, selected } ) => {

    return <div className={`pill ${( selected ) ? 'pill--selected' : ''}`}>
        <div className="label">{children || label}</div>
    </div >;
    
};

export default Pill;
