import React from 'react';

import IconArrow from '../images/svg/icon-arrow.svg';

import '../styles/components/button.scss';

const Button = ( { children, label, small, expanded, reversed, margin, onClick } ) => {

    return <div className={`button ${( small ) ? 'button--small' : ''} ${( expanded ) ? 'button--expanded' : ''} ${( reversed ) ? 'button--reversed' : ''} ${( margin ) ? 'button--margin' : ''}`} onClick={onClick}>
        <div className="label">{children || label}</div>
        <div className="hover">
            <div className="mask"></div>
            <div className="icon">
                <div className="fill"></div>
                <IconArrow />
            </div>
        </div>
    </div >;
    
};

export default Button;
