import React, { useState } from 'react';

import TransitionLink from 'gatsby-plugin-transition-link';
import IconCross from '../images/svg/icon-cross.svg';
import LogoNost from '../images/svg/logo-nost.svg';
import LogoRbs from '../images/svg/logo-rbs.svg';
import Logo from '../images/svg/logo.svg';

import { useScrollPosition } from '../components/scroll-position';
import { transition } from '../components/config';
import { useData } from './data';

import '../styles/components/navigation.scss';

const Navigation = () => {

    const [ collapsed, setCollapsed ] = useState( true );
    const { chapters } = useData();
    const scrollPosition = useScrollPosition();

    const linkProps = { onClick: () => { setCollapsed( !collapsed ); } };

    const renderArticleLinks = ( chapter ) => {
        return chapter.articles.map( ( article, index ) => {
            const { shortTitle, slug } = article.frontmatter;
            return <li key={index} {...linkProps}>
                <TransitionLink to={slug} {...transition} activeClassName="active" >{shortTitle}</TransitionLink>
            </li>;
        } );
    };

    const renderChapterLinks = () => {
        return chapters.map( ( chapter, index ) => {
            const { title, slug } = chapter.frontmatter;
            return <li key={index} {...linkProps}>
                <TransitionLink to={slug} {...transition} activeClassName="active">{title}<sup>{chapter.articles.length}</sup></TransitionLink>
                {( chapter.articles.length > 0 ) && <ul className="sub-sub-nav">
                    {renderArticleLinks( chapter )}
                </ul>
                }
            </li>;
        } );
    };

    return <nav className={`navigation ${( collapsed ) ? 'collapsed' : ''}`}>
        <section className="navigation-overlay">
            <section className="navigation-content container-fluid">
                <div className="row">
                    <div className="col-3 col-sm-4 col-sm-offset-1 col-xs-12 col-xs-offset-0">
                        <ul className="main-nav">
                            <li {...linkProps}><TransitionLink to="/" {...transition} activeClassName="active">Home</TransitionLink></li>
                            <li {...linkProps}><TransitionLink to="/about" {...transition} activeClassName="active">About</TransitionLink></li>
                            <li {...linkProps}><TransitionLink to="/team" {...transition} activeClassName="active">Team</TransitionLink></li>
                        </ul>
                    </div>
                    <div className="col-8 col-sm-6 col-xs-12">
                        <ul className="main-nav">
                            <li {...linkProps}><TransitionLink to="/chapters" {...transition} activeClassName="active">Chapters</TransitionLink></li>
                        </ul>
                        <ul className="sub-nav">
                            {renderChapterLinks()}
                        </ul>
                    </div>
                </div>
            </section>
            <section className="navigation-footer hide-xs">
                <div className="container-fluid">
                    <div className="row bottom">
                        <div className="col-6">
                            <p className="small"><span className="inverted">Get in touch:</span> <a href="mailto:mail@n-ost.org">mail@n-ost.org</a> | <a href="mailto:playbook@n-ost.org">playbook@n-ost.org</a></p>
                        </div>
                        <div className="col-6 end">
                            <a href="https://www.bosch-stiftung.de/" rel="noopener noreferrer nofollow"><LogoRbs className="logo-rbs" /></a>
                            <a href="https://n-ost.org/" rel="noopener noreferrer nofollow"><LogoNost className="logo-nost" /></a>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <section className="navigation-header">
            <div className={`logo ${( scrollPosition.y > 0 ) ? 'collapsed' : ''}`}><TransitionLink to="/" {...transition} onClick={() => { setCollapsed( true ); }}><Logo /></TransitionLink></div>
            <button className="toggle" onClick={() => setCollapsed( !collapsed )}>{( !collapsed ) ? 'Close' : 'Menu'} <div className="icon"><IconCross /></div></button>
        </section>
    </nav>;

};

export default Navigation;
