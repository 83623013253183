import React from 'react';

import '../../styles/components/article/box.scss';

const Box = ( { children } ) => {

    return <div className="box">
        {children}
    </div>;

};

export default Box;
