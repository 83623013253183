import React from 'react';

const Row = ( { children } ) => {

    return <div className="row">
        {children}
    </div>;

};

export default Row;
