import React from 'react';

import '../../styles/components/article/floating-image.scss';

const FloatingImage = ( { children, src, title, caption, float = 'left' } ) => {

    return <div className="floating-image">
        <figure className={`float-${float}`}>
            <img src={src} title={title} />
            {caption && <figcaption>{caption}</figcaption>}
        </figure>
        {children}
    </div>;

};

export default FloatingImage;
