
import React, { useState } from 'react';

import Button from '../button';
import Pill from '../pill';

import '../../styles/components/article/resources-list.scss';

const ResourceList = () => {

    // TODO: Move data to props from MDX

    const [ selectedTags, setSelectedTags ] = useState( { communication: [], remote: [], management: [] } );
    const [ currentTopic, setCurrentTopic ] = useState( null );

    const topics = {
        communication: {
            title: 'Communication tools',
            description: 'Messaging, conference calls…',
            fields: [ { id: 'uses', title: 'Other uses', colWidth: 3 }, { id: 'keywords', title: 'Keywords', colWidth: 3 } ],
            tags: [
                { id: 1, name: 'Has app' },
                { id: 2, name: 'Secure' },
                { id: 3, name: 'No privacy concerns' },
                { id: 4, name: 'Open source' },
                { id: 5, name: 'Free' },
                { id: 6, name: 'Cross device' },
                { id: 7, name: 'Reliable' },
            ],
            resources: [
                {
                    title: 'Big Blue Button',
                    owner: 'Open source project',
                    link: 'https://bigbluebutton.org/',
                    uses: 'Integrating system for taking minutes; suitable for online team work',
                    keywords: 'Video communication, Video conferencing, Presentation, Online in-real-life work, open source technology, collaborative software',
                    tags: [ 2, 3, 4, 5, 6, 7 ]
                },
                {
                    title: 'Cisco Webex',
                    owner: 'Cisco (US)',
                    link: 'https://www.webex.com/de/index.html',
                    uses: '',
                    keywords: 'Video conferencing, Video meeting, Audio meeting, Video chat, Screen sharing, Possible integration with tools such as Slack or Microsoft teams',
                    tags: [ 6 ]
                },
                {
                    title: 'Facebook Messenger',
                    owner: 'Biggest shareholders: CEO Mark Zuckerberg, Vanguard Group, BlackRock, FMR, and T. Rowe Price',
                    link: 'https://www.facebook.com/',
                    uses: 'Can be used as a conference app tool',
                    keywords: 'Globally known and used, Easy to use, Good for reaching out for potential sources, Good for putting people in touch; Past messages not always easily searcheable',
                    tags: [ 1, 5, 6, 7 ]
                },
                {
                    title: 'Jitsy',
                    owner: '8x8 company (US)',
                    link: 'https://jitsi.org/',
                    uses: 'Integration with Slack, Office 365, and Google Calendar; Livestream of Jitsy meetings to Youtube',
                    keywords: 'Easy to use, No app needed, No installation needed, Browser Based, Can protect the call with a password, Safe, No private information shared, ',
                    tags: [ 1, 2, 3, 4, 5, 6, 7 ]
                },
                {
                    title: 'Keybase',
                    owner: 'Zoom Video Communications (US)',
                    link: 'https://keybase.io/',
                    uses: 'File sharing, crypto currency wallet, team and subteam creation',
                    keywords: 'End-to-end encryption, file sharing, encrypted, secure communication, messaging, signature chain, blockchain, Git repository, crypto wallet, team organisation',
                    tags: [ 1, 2, 4, 5, 6, 7 ]
                },
                {
                    title: 'Microsoft Teams',
                    owner: 'Microsoft Corporation (US)',
                    link: 'https://www.microsoft.com/en-us/microsoft-365/microsoft-teams/group-chat-software/',
                    uses: 'Video conferencing, Presentations',
                    keywords: 'Easy to use, Video chat, Mesaging, Online meetings, Video calls, Conference calls, File sharing, Online collaboration ',
                    tags: [ 1, 2, 5, 6, 7 ]
                },
                {
                    title: 'Signal',
                    owner: 'Signal Technology Foundation (aka Signal Foundation), non profit (US)',
                    link: 'https://signal.org/en/',
                    uses: 'Can replace your phone\'s SMS messaging app, Can run on your Desktop',
                    keywords: '',
                    tags: [ 1, 2, 3, 4, 5, 6, 7 ]
                },
                {
                    title: 'Skype Meet now',
                    owner: 'Microsoft Corporation (US)',
                    link: 'https://www.skype.com/en/',
                    uses: 'Messaging, Call (audio) recording',
                    keywords: 'Video conferencing, video calls, browser based, blurred background, screen sharing, call recording',
                    tags: [ 1, 5 ]
                },
                {
                    title: 'Telegram ',
                    owner: 'Telegram Messenger (UK)',
                    link: 'https://telegram.org/',
                    uses: 'Can be used via desktop; Can be used to broadcast messages to an unlimited number of users; Suitable for research of groups and networks; Sharing live location possible, Bots can be created and run',
                    keywords: '',
                    tags: [ 1, 2, 4, 5, 6, 7 ]
                },
                {
                    title: 'Viber',
                    owner: 'Rakuten (Japan)',
                    link: 'https://www.viber.com/en/',
                    uses: 'Message deleting, Hidden chats, Secret Chats)',
                    keywords: 'Chat, Easy to use, Hidden chats, Secret chats, ',
                    tags: [ 1, 2, 5, 6, 7 ]
                },
                {
                    title: 'Zoho Meetings',
                    owner: 'Zoho corporation (India)',
                    link: 'https://www.zoho.com/meeting/',
                    uses: 'Presentation; Lets you even pull in text documents, spreadsheets, and other files easily amidst presentation',
                    keywords: 'Video chat, Video conference, Presentation',
                    tags: [ 1, 2, 3, 4, 6, 7 ]
                },
                {
                    title: 'Zoom',
                    owner: 'Zoom video corporations (US)',
                    link: 'https://zoom.us/about',
                    uses: 'Video conferencing, Presentations, Live stream to Youtube',
                    keywords: 'Easy to use, globally known and used, Retains some users\' data + metadata of the call, Work in smaller groups, Recording, Streaming',
                    tags: [ 1, 2, 3, 5, 6, 7 ]
                },
                {
                    title: 'Whatsapp',
                    owner: 'Facebook (US)',
                    link: 'https://www.whatsapp.com/',
                    uses: 'Can run on your Desktop; Stories',
                    keywords: 'End-to-end encryption, file sharing, video, easy to use, widespread use',
                    tags: [ 1, 5, 6, 7 ]
                },
                {
                    title: 'Whereby',
                    owner: 'Videonor (Norway)',
                    link: 'https://whereby.com/',
                    uses: 'Screen sharing + Screen sharing Google Doc, Miro Whiteboard, Trello Boards and Youtube',
                    keywords: 'Easy to use, No app needed, No installation needed, Browser Based, Can protect the call with a password',
                    tags: [ 1, 2, 3, 5, 6, 7 ]
                },
                {
                    title: 'Wire',
                    owner: 'Wire Swiss GmbH (Switzerland)',
                    link: 'https://wire.com/en/',
                    uses: 'Paid Version: video conferencing, audio calls, file-sharing, Memember role management, External collaboration and Guest rooms ',
                    keywords: 'End-to-end encryption, Secure chat, Group chat, text messages, Group messages; video conferencing (paid version), file sharing (paid version), work with external users (paid version)',
                    tags: [ 1, 2, 3, 4, 5, 6, 7 ]
                }
            ]
        },
        remote: {
            title: 'Remote work',
            description: 'Doc sharing, file sharing, co-editing',
            fields: [ { id: 'uses', title: 'Other uses', colWidth: 6 } ],
            tags: [
                { id: 1, name: 'Has app' },
                { id: 2, name: 'Secure' },
                { id: 3, name: 'No privacy concerns' },
                { id: 4, name: 'Open source' },
                { id: 5, name: 'Free' },
                { id: 6, name: 'Cross device' },
                { id: 7, name: 'Reliable' },
            ],
            resources: [
                {
                    title: 'Dropbox',
                    owner: 'Dropbox, Inc. (US)',
                    link: 'https://www.dropbox.com/',
                    uses: 'File sharing, file storage, Synchronization between documents on your computer and on the cloud, File and documents backup',
                    tags: [ 1, 5, 6, 7 ]
                },
                {
                    title: 'GitBook',
                    owner: 'Open source (team of developers based in France)',
                    link: 'https://www.gitbook.com/',
                    uses: 'Knowledge sharing (public and private), document co-editing, blogging/educating, Documenting a (reporting,research, investigation) progress',
                    tags: [ 2, 3, 4, 5, 6, 7 ]
                },
                {
                    title: 'Google Drive',
                    owner: 'Google',
                    link: 'https://www.google.com/intl/en-GB/drive/',
                    uses: 'Document/file sharing, co-editing, Calendar sharing, Agenda sharing, Poll creation, Creation of mailing lists and groups; Suitable if one needs to integrate other google-made features and apps',
                    tags: [ 1, 5, 6, 7 ]
                },
                {
                    title: 'IDrive',
                    owner: 'IDrive Inc (US)',
                    link: 'https://www.idrive.com/',
                    uses: 'Backup, file and document storage, file and document sharing, local backup, Multiple device backup, computer clone and backup, Optional end-to-end encryption, Fully-featured desktop client',
                    tags: [ 1, 5, 6, 7 ]
                },
                {
                    title: 'Maltego',
                    owner: 'Maltego Technologies GmbH (Germany)',
                    link: 'https://www.maltego.com/',
                    uses: 'Investigation, network visualisation, data combining, gathering data from different data sources, visual presentation: Cyber security investigations; Person of interest investigation; Fraud investigation',
                    tags: [ 1, 2, 5, 6, 7 ]
                },
                {
                    title: 'Mega',
                    owner: 'Mega Limited (New Zealand)',
                    link: 'https://mega.nz/',
                    uses: 'File sharing, file storage, co-editing, encrypted file sharing and storage, Synchronization between your computer and MEGA cloud files, Secure chat, Backup of documents',
                    tags: [ 1, 2, 5, 6, 7 ]
                },
                {
                    title: 'Nextcloud',
                    owner: 'Nextcloud GmbH (Germany)',
                    link: 'https://nextcloud.com/',
                    uses: 'Document/file sharing, co-editing, Calendar sharing, Agenda sharing, Assigning tasks (kanban), Compiling to-do lists',
                    tags: [ 1, 2, 3, 4, 5, 6, 7 ]
                },
                {
                    title: 'pCloud',
                    owner: 'pCloud AG (Switzerland)',
                    link: 'lihttps://www.pcloud.com/eunk',
                    uses: 'File storage, File and document sharing and synchronisation, Optional zero knowledge encryption feature, Can sync unencrypted data with Facebook and other services',
                    tags: [ 1, 2, 5, 6, 7 ]
                },
                {
                    title: 'SpiderOak',
                    owner: 'SpiderOak (US)',
                    link: 'https://spideroak.com/one/',
                    uses: 'File sharing, file storage, co-editing, encrypted file sharing and storage, Document backup, File sync, Encrypted group messaging, Encrypted chat (Semaphor), Remote telework',
                    tags: [ 1, 2, 3, 6, 7 ]
                },
                {
                    title: 'Sync.com',
                    owner: 'Sync.com (Canada)',
                    link: 'https://www.sync.com/',
                    uses: 'File sharing, file storage, end-to-end encryption, real-time backup, secure data storage, link sharing',
                    tags: [ 1, 2, 3, 6, 7 ]
                },
                {
                    title: 'Tresorit',
                    owner: 'Tresorit (Switzerland, Hungarian owners)',
                    link: 'https://tresorit.com/',
                    uses: 'File sharing, file storage, co-editing, encrypted file sharing and storage, Document backup, File sync, Remote telework, Supports external collaborators (and encrypts their files as well), File versioning (saving the previous versions of files)',
                    tags: [ 1, 2, 3, 5, 6, 7 ]
                },
                {
                    title: 'Zoho Meetings',
                    owner: 'Zoho corporation (India)',
                    link: 'https://www.zoho.com/meeting/',
                    uses: 'Webinars, Video calls, Presentations - easily sharing spreadsheets and files',
                    tags: [ 1, 2, 3, 4, 6, 7 ]
                },
                {
                    title: 'We Transfer',
                    owner: 'We Transfer company (Rinke Visser, Nalden, Bas Beerens[, based in Amsterdam)',
                    link: 'https://wetransfer.com/',
                    uses: 'File sharing, document sharing, photo sharing, sharing via link',
                    tags: [ 1, 5, 6, 7 ]
                },
            ]
        },
        management: {
            title: 'Project management',
            description: 'Meetings, scheduling, team management…',
            fields: [ { id: 'type', title: 'For type of work', colWidth: 3 }, { id: 'keywords', title: 'Keywords', colWidth: 3 } ],
            tags: [
                { id: 1, name: 'Has app' },
                { id: 2, name: 'Secure' },
                { id: 3, name: 'No privacy concerns' },
                { id: 4, name: 'Open source' },
                { id: 5, name: 'Free' },
                { id: 6, name: 'Cross device' },
                { id: 7, name: 'Reliable' },
            ],
            resources: [
                {
                    title: 'Asana',
                    owner: 'Asana, Inc. (US)',
                    link: 'https://asana.com',
                    type: 'Organising team work, tracking  tasks across teams and projects, Assignee and due dates view, Calendar view, Timeline view, Advanced search & reporting, Progress view, works both for small and large businesses (business option), Messaging option',
                    keywords: 'Team management, group work, Task assignement, Work progress monitoring, To-do-lists, Calendar view, Board view',
                    tags: [ 1, 2, 5, 6, 7 ]
                },
                {
                    title: 'Basecamp',
                    owner: 'oBasecamp LLC (US)wner',
                    link: 'https://basecamp.com/',
                    type: 'Doc sharing, File sharing, team management, organising team work, scheduling and assigning tasks, Automatic check-ins, Group chat, Message board, To-do lists, Direct messages, Email forwards, Notifications',
                    keywords: 'Small businesses, mid-size businesses, project management, to-do lists, document sharing, file sharing, editing, schedule, calendar',
                    tags: [ 1, 5, 6, 7 ]
                },
                {
                    title: 'Discourse',
                    owner: 'Community (Open source)',
                    link: 'https://www.discourse.org/',
                    type: 'Group communication, community engagement, keeping a community alive, building a team whose members don\'t necessarily know each other, community building, link sharing, organising information in topics and subtopics, Setting up mailing list, Promotion, Attachments included, Live messaging, Private messaging other members',
                    keywords: 'community building, community engaging, large teams, forum, message board, large groups, Mailing list',
                    tags: [ 1, 2, 3, 4, 5, 6, 7 ]
                },
                {
                    title: 'Microsoft Planner',
                    owner: 'Microsoft (US)',
                    link: 'https://tasks.office.com/',
                    type: 'Team management, Task assignement, To-do lists, Deadline assignement, Task prioritisation',
                    keywords: 'Microsoft, Kanban interface, Groups, Bucket lists, To-do lists, Comments, Charts, ',
                    tags: [ 1, 6, 7 ]
                },
                {
                    title: 'Miro',
                    owner: 'Miro (US)',
                    link: 'https://miro.com/',
                    type: 'Online collaboration, presentation sharing, document sharing, organising teams and projects, visualisation of concepts, processes and structures, collaborative brainstorming, ',
                    keywords: 'Small businesses, mid-size businesses, project management, to-do lists, document sharing, file sharing, editing, schedule, calendar, online whiteboard, templates, tool integration',
                    tags: [ 1, 5, 6, 7 ]
                },
                {
                    title: 'Monday.com',
                    owner: 'Nextcloud GmbH, Community (opens source)',
                    link: 'https://monday.com/',
                    type: 'Planning work, Tracking ongoing work, Tracking completed assignements, Automating repetitive work, Visualising work with data, Visualising workflow, Organising workflow, Integration of other apps and softwares is possible, Organising virtual workspaces, File sharing, Organising work in subitems, Kanban view',
                    keywords: 'Team management, group work, Task assignement, Work progress monitoring, To-do-lists, Organising daily tasks, Tracking time, Managing production work, Managing finances, Managing large investigation, Managing large storytelling project, Visualising work, Setting up digital workspaces, File sharing, time tracking',
                    tags: [ 1, 6, 7 ]
                },
                {
                    title: 'NextCloud',
                    owner: 'Nextcloud GmbH (Germany)',
                    link: 'https://nextcloud.com/',
                    type: 'Document/file sharing, co-editing, Calendar sharing, Agenda sharing, Assigning tasks (kanban), Compiling to-do lists',
                    keywords: 'Document sharing, file sharing, Editing, Encrypted, Secure, Open source, Free, Kanban, To-do list, Assignments, Calendar, List',
                    tags: [ 1, 2, 3, 4, 5, 6, 7 ]
                },
                {
                    title: 'Slack',
                    owner: 'Slack Technologies (major investors include well-known venture capital firms like Andreessen Horowitz, Social Capital, Accel, Spark Growth, Comcast), US',
                    link: 'https://slack.com/intl/en-de/',
                    type: 'Organising team work, breaking down large groups in smaller teams, direct messaging, one-to-one calls, group calls (paid option)',
                    keywords: 'Team management, group work, One-to-one work and communication, Task assignement, Group communication',
                    tags: [ 1, 5, 6, 7 ]
                },
                {
                    title: 'Trello',
                    owner: 'Atlassian (Australia)',
                    link: 'https://trello.com/en',
                    type: 'Organising team work, distributing tasks among group members and tracking their prigress, Organising different stages of everyone\'s work, Setting up reminders and to-do-lists',
                    keywords: 'Team management, group work, One-to-one work and communication, Task assignement, Work progress monitoring, To-do-lists, Organising daily tasks, Tracking time, Managing production work, Managing finances, Managing large investigation, Managing large storytelling project',
                    tags: [ 1, 5, 6, 7 ]
                },

            ]
        }
    };

    const renderTopics = () => {
        if ( !currentTopic ) {
            return <>
                <div>
                    <Button expanded small onClick={() => { setCurrentTopic( 'communication' ); }}>{topics.communication.title}</Button>
                </div>
                <div>
                    <Button expanded small onClick={() => { setCurrentTopic( 'remote' ); }}>{topics.remote.title}</Button>
                </div>
                <div>
                    <Button expanded small onClick={() => { setCurrentTopic( 'management' ); }}>{topics.management.title}</Button>
                </div>
            </>;
        }
    };

    const renderResources = () => {
        if ( currentTopic ) {
            return <>
                <Button reversed expanded small onClick={() => { setCurrentTopic( null ); }}>Previous step</Button>
                <h2 className="title">{topics[currentTopic].title}</h2>
                <div className="tags">
                    {renderTagsForCurrentTopic()}
                </div>
                <section className="resources">
                    {renderResourcesForTags()}
                </section>
            </>;
        }
    };

    const renderTagsForCurrentTopic = () => {
        return topics[currentTopic].tags.map( ( tag, index ) => {
            const selected = selectedTags[currentTopic].includes( tag.id );
            return <div className="tag" key={index} onClick={() => { clickTag( tag ); }}><Pill label={tag.name} selected={selected} /></div>;
        } );
    };



    const clickTag = ( tag ) => {
        setSelectedTags( ( prevState ) => {
            const newState = prevState;
            const selected = prevState[currentTopic].includes( tag.id );

            if ( !selected ) {
                newState[currentTopic].push( tag.id );
            } else {
                newState[currentTopic] = newState[currentTopic].filter( id => id !== tag.id );
            }

            return { ...prevState, ...newState };
        } );
    };

    const renderResourcesForTags = () => {
        return topics[currentTopic].resources.map( ( resource, index ) => {

            let selected = selectedTags[currentTopic].every( ( id ) => resource.tags.includes( id ) );
            return <a key={index} href={resource.link} target="_blank" rel="noopener noreferrer nofollow">
                <div className={`row resource middle ${selected ? 'selected' : ''}`}>
                    <div className="col col-4 col-sm-10 title"><h3>{resource.title}</h3></div>
                    {renderFieldsForResource( resource )}
                    <div className="col col-1 end"><Button small /></div>
                    <div className="col-11"><div className="line"></div></div>
                </div>
            </a>;

        } );
    };

    const renderFieldsForResource = ( resource ) => {
        return topics[currentTopic].fields.map( ( field, index ) => {
            return <div className={`col-${field.colWidth} col-sm-12 hide-xs`} key={index}>{resource[field.id]}</div>;
        } );
    };

    return <section className="resources-list">
        <div className="container-fluid">
            {renderTopics()}
            {renderResources()}
        </div>
    </section>;

};

export default ResourceList;
