import { useState, useEffect } from 'react';

export function useScrollPosition() {

    const [ scrollPosition, setSrollPosition ] = useState( { x: 0, y: 0 } );

    const handleScroll = () => {
        setSrollPosition( { x: window.pageXOffset, y: window.pageYOffset } );
    };

    useEffect( () => {
        window.addEventListener( 'scroll', handleScroll, { passive: true } );
        return () => {
            window.removeEventListener( 'scroll', handleScroll );
        };
    }, [] );

    return scrollPosition;
}