import { useStaticQuery, graphql } from 'gatsby';

export const useData = () => {

    const result = useStaticQuery( graphql`
    query {
      allMdx(
        sort: { order: ASC, fields: [frontmatter___name, frontmatter___order] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              id
              slug
              type
              chapter
              title
              shortTitle
              name
              info
              description
              shortDescription
              authors
              iconPath
              iconSmallPath
              image
              linkedin
              twitter
              instagram
              facebook
              website
            }
          }
        }
      }
    }
    `
    );

    let nodes = result.allMdx.edges;
    nodes = nodes.map( node => node.node );

    const chapters = nodes.filter( node => node.frontmatter.type === 'chapter' );
    const articles = nodes.filter( node => node.frontmatter.type === 'article' );
    const authors = nodes.filter( node => node.frontmatter.type === 'author' );

    chapters.map( ( chapter ) => {
        chapter.articles = articles.filter( article => article.frontmatter.chapter === chapter.frontmatter.id );
    } );

    const articlesOrdered = chapters.reduce( ( articles, chapter ) => {
        return [ ...articles, ...chapter.articles ];
    }, [] );

    const getAuthorforId = ( id ) => {
        return authors.filter( a => a.frontmatter.id === id )[0];
    };

    const getAuthorsforIds = ( array ) => {
        return array.map( ( id ) => {
            return getAuthorforId( id );
        } );
    };

    const getIndexForArticle = ( array, article ) => {
        return array.map( a => a.frontmatter.id ).indexOf( article.frontmatter.id );
    };

    const getNextArticle = ( article ) => {
        const index = getIndexForArticle( articlesOrdered, article );
        return ( index === articlesOrdered.length - 1 ) ? articlesOrdered[0] : articlesOrdered[index + 1];
    };

    const getPreviousArticle = ( article ) => {
        const index = getIndexForArticle( articlesOrdered, article );
        return ( index === 0 ) ? articlesOrdered[articlesOrdered.length - 1] : articlesOrdered[index - 1];
    };

    const getArticlePosition = ( article, chapter ) => {
        const articles = chapters.filter( c => c.frontmatter.id === chapter.frontmatter.id )[0].articles;
        const index = getIndexForArticle( articles, article );
        return `${index + 1} / ${articles.length}`;
    };

    return { nodes, authors, chapters, articles, getNextArticle, getPreviousArticle, getArticlePosition, getAuthorforId, getAuthorsforIds };

};
