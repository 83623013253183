import React from 'react';

const Column = ( { width, children } ) => {

    return <div className={`col-${width} col-xs-12`}>
        {children}
    </div>;

};

export default Column;
