import React from 'react';

import '../styles/components/quote.scss';

const Quote = ( { children } ) => {
    return <section className="quote">
        {children}
    </section>;
};

export default Quote;
