import React from 'react';

import '../../styles/components/article/podcast.scss';

const Podcast = ( { src } ) => {

    return <div className="podcast"><iframe frameBorder="no" scrolling="no" seamless src={src}></iframe></div>;

};

export default Podcast;
