import React from 'react';

import '../../styles/components/article/image.scss';

const Image = ( { credit, src } ) => {

    return <div className={'image'}>
        <img src={src} />
        <small>
            <p>
                {credit}
            </p>
        </small>
    </div>;

};

export default Image;
